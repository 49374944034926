<script>
import SearchCombination from "@/view/search/SearchCombination.vue";
import {download} from "@/utils/downLoad";
export default {
  components: {SearchCombination},
  data(){
    return{
      tableData: null,
      pageSize: 20,
      pageNum: 1,
      total: 0,
    }
  },
  created() {
    this.getBlackList()
  },
  methods: {
    /**
     * 获取黑名单列表
     * @param data {Object} 查询条件
     * @param mod {boolean} 是否有提示
     * @param pageNum {number | null} 是否有提示
     */
    getBlackList(data = null, mod = false, pageNum = null){
      this.axios.get(this.$api.publicUrl.NewExpenseStat, {
        params: {
          pageSize: this.pageSize,
          pageNum: pageNum || this.pageNum,
          ...data
        }
      }).then(res =>{
        if(res.data.status === 200){
          this.total = res.data.data.total
          this.initData(res.data.data)
          // this.tableData = res.data.data
          if(mod){
            this.$message.success("查询成功！")
          }
        }else {
          this.$message(res.data.data.message)
        }
      })
    },
    /**
     * 1、创建模板数据； 2、调用创建弹出层的方法
     * @param ti  {string}  标题
     * @param mod {string}  方式；例如 添加、修改
     * @param data  {Object}  源数据； 最终弹出层的回调事件会将修改后的源数据返回，以便于后续提交表单数据
     */
    createTemplateData(ti, mod, data = null){
      this.$createPopup("form",{
        ti: ti,
        tree: [
          [{label: "昵称", type: "text", key: "nickname", prop: "nickname"}, {label: "姓名", type: "text", key: "userName", prop: "userName"}],
          [{label: "电话", type: "number", key: "phoneNumber", prop: "phoneNumber"}, {label: "来源", type: "select", key: "userSource", prop: "userSource",
            options: [
              {value: 'HTML', label: 'HTML'},
              {value: 'CSS', label: 'CSS'},
              {value: 'JavaScript', label: 'JavaScript'}
            ]}
          ],
          [{label: "拉黑原因", type: "textarea", key: "remark", prop: "remark"}]
        ],
        formData: data || {
          nickname: "",
          userName: "",
          phoneNumber: "",
          userSource: "",
          remark: "",
        },
        rules: {
          userName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
          phoneNumber: [{ required: true, message: '请输入电话', trigger: 'blur' }],
          remark: [{ required: true, message: '请输入拉黑原因', trigger: 'blur'}],
        }
      }, mod, this.poPupCallback)
    },
    /**
     * 搜索组件的回调
     * @param data {Object} 搜索的条件数据
     */
    searchCallBack(data){
      this.getBlackList(data, true, 1)
    },
    /**
     * 导出
     */
    orderExport(){
      this.$createPopup("form",{
        ti: "费用导出",
        tree: [
          [{label: "日期范围", type: "month", key: "dateRange", prop: "dateRange", format: null, valueFormat: "yyyy-MM"}],
        ],
        formData: {
          dateRange: "",
        },
        rules: {
          dateRange: [{ required: true, message: '请选择日期区间', trigger: 'change'}],
        }
      }, null, this.poPupCallback)
    },
    /**
     * 弹出层回调方法
     * @param data
     */
    poPupCallback(data){
      let arr = data.dateRange.split("-")
      this.sendService({
        startTime: `${data.dateRange}-01`,
        stopTime: `${data.dateRange}-${new Date(parseInt(arr[0]), parseInt(arr[1]), 0).getDate()}`
      }, data.dateRange)
    },
    /**
     * 向服务器发送请求
     * @param data
     * @param ti
     */
    sendService(data, ti){
      download(data, `${ti}费用数据`, "post", this.$api.publicUrl.ExpenseExport)
    },

    initData(data){
      let arr = []
      for (let x = 0; x < data.length; x ++){
        let XFirst = true
        for(let y = 0; y < data[x].orderList.length; y++){
          let YFirst = true
          for(let z = 0; z < data[x].orderList[y].costList.length; z++){
            let obj = {
              id: null,
              operatorName: null,
              orderId: null,
              ...data[x].orderList[y].costList[z]
            }
            if(XFirst){
              obj.id = x + 1
              obj.operatorName = data[x].operatorName
              XFirst = !XFirst
            }
            if(YFirst){
              obj.orderId = data[x].orderList[y].orderId
              obj.NameMerge = 0
              data[x].orderList.forEach(item => {
                obj.NameMerge += item.costList.length
              })
              YFirst = !YFirst
            }
            if(z === 0){
              obj.orderMerge = data[x].orderList[y].costList.length
            }
            arr.push(obj)
          }
        }
      }
      this.tableData = arr
    },

    handleSizeChange(val) {
      this.pageSize = val
      this.getBlackList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getBlackList()
    }
  }
}
</script>

<template>
  <fragment v-if="tableData">
    <h1 class="title">请输入查询条件</h1>
    <section class="search">
      <SearchCombination :data="{
        list: [
          [
            {ti: '操作人姓名', type: 'input', class: 'min', key: 'operatorName'},
            {ti: '日期区间', type: 'datePicker', class: 'min', key: ['startTime', 'stopTime']},
          ]
        ],
        senior: false
      }" @searchCallBack="searchCallBack"></SearchCombination>
    </section>
    <section class="control">
      <div class="con_ti"></div>
      <div class="btn">
        <el-button type="primary" @click="orderExport" size="small">导出</el-button>
      </div>
    </section>
    <section class="tabulation" v-if="tableData">
      <table>
        <tr>
          <th>编号</th>
          <th>司机</th>
          <th>订单ID</th>
          <th>费用类型</th>
          <th>金额</th>
        </tr>
        <tr v-for="item in tableData" :key="item.id">
          <td v-if="item.id" :rowspan="item.NameMerge">{{item.id}}</td>
          <td v-if="item.operatorName" :rowspan="item.NameMerge">{{item.operatorName}}</td>
          <td v-if="item.orderId" :rowspan="item.orderMerge">{{item.orderId}}</td>
          <td>{{item.costName}}</td>
          <td>{{item.amount}}</td>
        </tr>
      </table>
    </section>
<!--    <section class="pagination">-->
<!--      <el-pagination-->
<!--        background-->
<!--        @size-change="handleSizeChange"-->
<!--        @current-change="handleCurrentChange"-->
<!--        :current-page="this.pageNum"-->
<!--        :page-sizes="[10, 20, 50, 100]"-->
<!--        :page-size="this.pageSize"-->
<!--        layout="total, sizes, prev, pager, next, jumper"-->
<!--        :total="this.total">-->
<!--      </el-pagination>-->
<!--    </section>-->
  </fragment>
  <fragment v-else>
    <img src="../../../assets/images/loading.gif" class="loading" height="100" width="100" alt="加载中"/>
  </fragment>
</template>
<style src="@/assets/css/componentStyle.css" scoped></style>
<style scoped>
table{
  width: 100%;
  border-collapse: collapse
}
th, td{
  padding: 10px 20px;
  border: 1px solid #ddd;
  text-align: center;
}
td{
  min-height: 42px;
}
._status{
  position: relative;
}
._status span{
  position: absolute;
}
tr{
  position: relative;
  transition: all 0.3s;
}
tr:hover{
  background-color: #dedede;
}
tr:hover .t_btn{
  opacity: 1;
  pointer-events: auto;
  cursor: pointer;
}
._brand, ._series{
  background-color: white!important;
  text-align: center;
}
tr:nth-child(1), tr:nth-child(2){
  pointer-events: none;
}
.on{
  background-color: #358f6c;
  color: white;
  padding: 2px 5px;
  border-radius: 2px;
  font-size: 12px;
  margin-left: 50px;
}
.t_btn{
  position: absolute;
  right: 0;
  display: flex;
  opacity: 0;
  padding: 0 20px;
  transition: all 0.3s;
  pointer-events: none;
  background-color: #dedede;
}
</style>
