var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.tableData)?_c('fragment',[_c('h1',{staticClass:"title"},[_vm._v("请输入查询条件")]),_c('section',{staticClass:"search"},[_c('SearchCombination',{attrs:{"data":{
        list: [
          [
            {ti: '操作人姓名', type: 'input', class: 'min', key: 'operatorName'},
            {ti: '日期区间', type: 'datePicker', class: 'min', key: ['startTime', 'stopTime']},
          ]
        ],
        senior: false
      }},on:{"searchCallBack":_vm.searchCallBack}})],1),_c('section',{staticClass:"control"},[_c('div',{staticClass:"con_ti"}),_c('div',{staticClass:"btn"},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.orderExport}},[_vm._v("导出")])],1)]),(_vm.tableData)?_c('section',{staticClass:"tabulation"},[_c('table',[_c('tr',[_c('th',[_vm._v("编号")]),_c('th',[_vm._v("司机")]),_c('th',[_vm._v("订单ID")]),_c('th',[_vm._v("费用类型")]),_c('th',[_vm._v("金额")])]),_vm._l((_vm.tableData),function(item){return _c('tr',{key:item.id},[(item.id)?_c('td',{attrs:{"rowspan":item.NameMerge}},[_vm._v(_vm._s(item.id))]):_vm._e(),(item.operatorName)?_c('td',{attrs:{"rowspan":item.NameMerge}},[_vm._v(_vm._s(item.operatorName))]):_vm._e(),(item.orderId)?_c('td',{attrs:{"rowspan":item.orderMerge}},[_vm._v(_vm._s(item.orderId))]):_vm._e(),_c('td',[_vm._v(_vm._s(item.costName))]),_c('td',[_vm._v(_vm._s(item.amount))])])})],2)]):_vm._e()]):_c('fragment',[_c('img',{staticClass:"loading",attrs:{"src":require("../../../assets/images/loading.gif"),"height":"100","width":"100","alt":"加载中"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }